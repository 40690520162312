import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });

const FormService = {
  createLeadForRates: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/createLeadForRates',
        timeout: 26000,
        data: leadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateMailDetailsForLead: (mailData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: '/api/updateMailDetailsForLead',
        data: mailData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateQTSalesforceLead: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/updateQTSalesforceLead',
        timeout: 26000,
        data: leadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then((resp) => {
          console.log(
            'Update lead at address[exit intent popup] for QT ',
            resp
          );
          resolve(resp);
        })
        .catch((err) => {
          console.log('Form Service error from updateQTSalesforceLead: ', err);
          reject(err);
        });
    });
  },
  updateSalesforceLead: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: '/api/updateSalesforceLead',
        data: leadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },
  sendEmail: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/email',
        data: leadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },
  // createLeadInDB: (leadData) => {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       method: 'post',
  //       url: '/api/storePrimaryInDB',
  //       data: leadData,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json;charset=UTF-8',
  //       },
  //     })
  //       .then(() => resolve())
  //       .catch((err) => reject(err));
  //   });
  // },
  updateLeadInDB: (leadData) => {
    return new Promise((resolve, reject) => {
      axiosRetry(axios, { retries: 3 });
      axios({
        method: 'post',
        url: '/api/storeSuppInDB',
        data: leadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },
  fireLeadConversionEvent: () => {
    if (typeof window !== 'undefined') {
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          event_category: 'goal',
          event_label: 'lead',
        });
      }
    }
  },
  fetchQtPassThroughToken: async (salesforceLeadId) => {
    const res = await axios({
      method: 'post',
      url: `/api/fetchQtPassThroughToken`,
      data: { salesforceLeadId },
      timeout: 26000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return res.data;
  },
  updatePreferredPhone: async (phone, salesforceLeadId) => {
    try {
      const res = await axios({
        method: 'post',
        url: `/api/updatePreferredPhone`,
        data: { phone, salesforceLeadId },
        timeout: 26000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });
      return res.data;
    } catch (error) {
      console.log('error sheet', error);
    }
  },
  fetchCustomerDetails: async (salesforceLeadId) => {
    const res = await axios({
      method: 'post',
      url: `/api/fetchCustomerDetails`,
      data: { salesforceLeadId },
      timeout: 26000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return res.data;
  },
  submitConfirmedContactDetails: async (data, salesforceLeadId) => {
    const res = await axios({
      method: 'post',
      url: '/api/submitConfirmedContactDetails',
      timeout: 26000,
      data: {
        requestBody: data,
        salesforceLeadId,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
    return res.data;
  },
};

export default FormService;
