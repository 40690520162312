import { browserName, deviceDetect } from 'react-device-detect';
import { isArray, isEmpty } from 'lodash';

import DeviceDetector from 'device-detector-js';
import Filter from 'bad-words';

const deviceDetector = new DeviceDetector();
const filter = new Filter();
const isBrowser = typeof window !== 'undefined';

const FormUtils = {
  cleansePhone: (num: string) => {
    return num.replace(/[^0-9]/g, '');
  },

  getURL: () => {
    return typeof window !== 'undefined' ? window.location.href : '';
  },

  cleanUrlFragment: (urlString: string) => {
    return urlString.split('#')[0];
  },

  getDeviceDetect: () => {
    return deviceDetect(undefined);
  },

  getOS: () => {
    const device = deviceDetect(undefined);
    return device.osName ? device.osName : '';
  },

  getOSVersion: () => {
    const device = deviceDetect(undefined);
    return device.osVersion ? device.osVersion : '';
  },

  getBrowserName: () => {
    return browserName;
  },

  getDeviceDetails: () => {
    if (isBrowser) {
      const deviceDetails = deviceDetector.parse(
        deviceDetect(undefined).userAgent
      );
      return deviceDetails.device;
    }
    return {};
  },

  getWindow: () => {
    if (isBrowser) {
      return global.window;
    }
    return null;
  },

  getDocument: () => {
    if (isBrowser) {
      return global.document;
    }
    return null;
  },

  getAge: (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },

  getCurrentDate: () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const h = today.getHours();
    const m = today.getMinutes();
    const s = today.getSeconds();

    return mm + '/' + dd + '/' + yyyy + ' - ' + h + ':' + m + ':' + s;
  },

  cleanString: (inputString: string) => {
    if (!isEmpty()) return filter.clean(inputString).replaceAll('*', '');
    return inputString;
  },

  replaceSemicolonWithComma: (inputString: string) => {
    const outputString = inputString.replace(/;/g, ',');
    return outputString;
  },

  scrollToTop: () => {
    if (global.window) {
      setTimeout(() => {
        global.window.scrollTo(0, 0);
      }, 200);
    }
  },

  isQueryParamEmpty: (queryParamValue: string) => {
    if (
      isEmpty(queryParamValue) ||
      queryParamValue === '' ||
      queryParamValue === 'undefined' ||
      queryParamValue === 'null'
    )
      return true;
    return false;
  },

  queryParamArrayCheck: (queryParamValue: string | string[]) => {
    if (isArray(queryParamValue)) {
      if (
        FormUtils.isQueryParamEmpty(queryParamValue[queryParamValue.length - 1])
      ) {
        return '';
      }
      return queryParamValue[queryParamValue.length - 1]?.trim();
    } else {
      return queryParamValue?.trim();
    }
  },

  formatPhoneNumber: (number: number | string | undefined | null) => {
    if (!number) {
      return number;
    }
    return String(number).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  },

  getQueryParamValue: (field: string): string => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      if (params.has(field) && params.get(field)) {
        return params.get(field) || '';
      }
    }
    return '';
  },
};

export default FormUtils;
