import React, { useState } from 'react';

import FormUtils from '../utils/formUtils';
import PropTypes from 'prop-types';
import fetchUrlFromCDN from '../service/cdnService';
import { getSearchParams } from 'gatsby-query-params';
import { isEmpty } from 'lodash';

export const BrandLogoContext = React.createContext({
  brandImages: undefined,
});

const BrandLogoContextProvider = ({ children }) => {
  const qp = getSearchParams();
  const [brandImages, setBrandImages] = useState(undefined);
  React.useEffect(() => {
    let leadBrandText = !FormUtils.isQueryParamEmpty(qp.leadBrand)
      ? Array.isArray(qp.leadBrand) //If unknowingly lead brand is passed multiple times and value gets read as array
        ? qp.leadBrand[0].toString()
        : qp.leadBrand.toString()
      : 'medicare compared';

    if (!isEmpty(leadBrandText)) {
      fetchUrlFromCDN(leadBrandText)
        .then((resp) => {
          'logo' in resp && setBrandImages(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [qp]);

  return (
    <BrandLogoContext.Provider value={{ brandImages: brandImages }}>
      {children}
    </BrandLogoContext.Provider>
  );
};

BrandLogoContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default BrandLogoContextProvider;
