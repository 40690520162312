import './src/styles/index.scss';

import { ActionContextProvider, createConfig } from './src/cra';

import BrandLogoContextProvider from './src/context/BrandLogoContextProvider';
import Cookies from 'universal-cookie';
import React from 'react';
import addressSectionExitIntentUtils from './src/utils/addressSectionExitIntentUtils';

export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Demographics',
  });

  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    cookies.remove('exit-intent', {
      path: '/',
      domain: window.location.hostname,
    });
  }

  return (
    <ActionContextProvider
      exitIntentCallback={addressSectionExitIntentUtils.exitIntentCallback}
      routeToQuotingTool={addressSectionExitIntentUtils.routeToQuotingTool}
    >
      <BrandLogoContextProvider>{element}</BrandLogoContextProvider>
    </ActionContextProvider>
  );
};
